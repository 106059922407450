footer {
  bottom: 0;
  display: flex;
  background-color: var(--og-footer-color);
  padding: 20px;
}

.footer-contents-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.copyright-message {
  display: flex;
  color: #ffffff;
  font-size: 1rem;
  font-family: "Roboto";
  font-weight: bold;
}

a {
  text-decoration: none;
}

footer svg {
  height: 2vw;
  width: 2vw;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .footer-contents-container {
    flex-direction: column;
  }
  .collaboration-message-link {
    flex-basis: 40%;
    display: flex;
  }

  footer svg {
    height: 7vw;
    width: 7vw;
    color: #ffffff;
  }
}
