:root {
  --bg: #fcfcfc;
  --bg-accent: #f0f0f0;
  --bg-hover: #dcdcdc;
  --text-color: #656fb3;
  --text-subtitle-color: #565fb0;
  --text-title-color: #4a4da2;
  --text-header-color: #412675;
  --text-header-shadow-color: rgba(0, 0, 0, 0.5);
  --nav-bottom: #3e2076;
  --nav-size: 60px;
  --border: 1.5px solid #474a4d;
  --border-radius: 8px;
  --speed: 300ms;
  --moon-color: #c0c0c0;
  --nav-toggle: #f38f07;
  --og-footer-color: #2c2d2e;
  --card-bg: #fff;
  --card-text: var(--text-subtitle-color);
  --sun-color: rgba(255, 155, 12, 0.7);
  --backgroundImg-buttom: #474a4d;
  --backgroundImg-buttom-background: rgba(255, 255, 255, 30%);
  --backgroundImg-cover-opacity: 0.5;
}

.dark-mode {
  --bg: #2c2d2e;
  --bg-accent: #484a4d;
  --bg-hover: #343435;
  --text-color: #d5cedc;
  --text-title-color: #d5c6f8;
  --og-footer-color: #2c2d2e;
  --text-subtitle-color: #beb8eb;
  --nav-toggle: #d3d1d1;
  --text-header-color: #ffeaee;
  --text-header-shadow-color: rgba(255, 255, 255, 1);
  --nav-bottom: #a277f4;
  --border: 1px solid #474a4d;
  --card-bg: var(--bg-accent);
  --card-text: #d4ccdd;
  --backgroundImg-buttom: white;
  --backgroundImg-buttom-background: rgba(0, 0, 0, 30%);
  --backgroundImg-cover-opacity: 0.4;
}

* {
  font-family:
    "Roboto",
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Droid Sans",
    "Helvetica Neue";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* testing */

.hotfix {
  display: none;
}

footer {
  text-align: center;
  flex-direction: column;
}

.hotfix div {
  padding-top: 1rem;
}

footer h3 {
  color: var(--bg-color);
}

.fa {
  padding: 20px;
  font-size: 25px;
  width: 25px;
  margin: 5px 4px;
  border-radius: 62px;
  color: #dcd9f3;
}

.fa-envelope {
  background: #3d94eb;
}

.fa-linkedin {
  background: #007bb5;
}

.fa-instagram {
  background: linear-gradient(145deg, #3051f1 10%, #c92bb7 70%) no-repeat;
}

.fa-github {
  background: #0d2028;
}

.fa-threads {
  background: #f1a665;
}

.github-in-line,
.fa-link {
  width: 10px;
  margin: 0 10px;
  padding: 0px;
  border-radius: 50%;
  background: none;
}

.links {
  list-style: none;
  display: flex;
  justify-content: center;
}

.links a {
  font-size: 1rem;
  transition: 0.3s;
}

.links a:hover {
  transition: 0.3s;
}

.copyright {
  padding: 0.5rem;
  text-align: center;
}

.copyright a {
  color: white;
}

a:hover {
  opacity: 0.7;
  transition: 0.2s;
}

.copyright a:hover {
  transition: 0.3s;
}

@media (max-width: 768px) {
  .og-footer {
    display: none;
  }

  .hotfix {
    display: flex;
  }
}
