.clubTitleSection {
  position: relative;
  width: 100%;
  height: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.87);
}

.background-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: var(--backgroundImg-cover-opacity);
  transition: opacity 1.2s ease-in-out;
}

.preload-images {
  display: none;
}

.preload-images img {
  display: none;
}

.uw-logo {
  transition: opacity 0.5s ease-in-out;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.uw-logo,
.club-introduction-container h1,
.club-introduction-container p {
  animation: slide-up 0.5s ease-out;
  z-index: 1;
}

.uw-logo {
  width: 25%;
  margin-top: 55px;
  filter: brightness(130%) contrast(85%)
    drop-shadow(5px 4px 8px rgba(255, 255, 255, 1));
}

.title-buttons-container,
.buttons-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 500;
}

.title-buttons-container {
  top: 50%;
  width: 90%;
}

.buttons-container {
  top: 30%;
  width: 100%;
}

.prevImgButton,
.nextImgButton,
.posts-carousel--left,
.posts-carousel--right {
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: var(--backgroundImg-buttom-background);
  color: var(--backgroundImg-buttom);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.prevImgButton:hover,
.nextImgButton:hover,
.posts-carousel--left:hover,
.posts-carousel--right:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.material-symbols-outlined:hover {
  opacity: 30%;
}

.club-introduction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.club-introduction-container h1 {
  margin-bottom: 10px;
  font-size: 5vw;
  color: white;
  font-style: italic;
  text-shadow: 1px 1px 2px var(--text-header-shadow-color);
}

.club-introduction-container p {
  font-size: 2vw;
  color: white;
  font-family: "Bell MT";
  font-style: italic;
  text-shadow: 1px 1px 2px var(--text-header-shadow-color);
}

.club-introduction-container-button {
  text-align: center;
  background-color: rgba(103, 38, 213);
  color: #ffffff;
  border: none;
  padding: 0.6em 1em 0.6em 1em;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  font-weight: 300;
  border-radius: 30px;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 500;
}

.club-introduction-container-button:hover {
  opacity: 0.6;
}

h3 {
  color: var(--text-title-color);
}

.summaryTitle,
.whyJoinUs h2 {
  color: var(--text-title-color);
  margin: 10px 0;
}

.summaryTitle,
.whyJoinUs h1 {
  font-size: 2rem;
  margin: 10px;
}

.summary,
.whyJoinUs p {
  font-size: 1.5rem;
  color: var(--text-color);
  line-height: 1.5;
}
.homepage p {
  font-size: 20px;
  color: var(--text-color);
}

.mainPage-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 18% 0 18%;
}

.map-and-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.map {
  width: 400px;
  height: auto;
  margin: 30px 30px 0 30px;
  border-radius: 8px;
}

.mapLabel {
  color: var(--text-color);
  font-size: 16px;
}

.goal-message-section {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
}

.goal-title {
  font-size: 3em;
  transform: translate(0%, -70%);
  color: var(--text-title-color);
}

.goal-text {
  text-align: center;
  min-width: 10%;
  max-width: 1000px;
  max-height: 50%;
  min-height: 20%;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 52px;
  transform: translate(0%, 10%);
  color: var(--text-subtitle-color);
}

.posts-carousel {
  margin-top: 5%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.posts {
  width: 90%;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 2rem;
}

.instaPost {
  flex: 0 0 auto;
  width: calc((100% - 2rem) / 3);
  justify-content: flex-start;
  scroll-snap-align: start;
}

.posts::-webkit-scrollbar {
  display: none;
}

.whyJoinUs {
  position: relative;
  margin: 0 15% 0 15%;
  padding: 2rem;
  border-top: 3px dotted rgba(115, 115, 115, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-up {
  animation: slide-up 0.5s ease-out;
}

@media (max-width: 1500px) {
  .instaPost {
    width: calc((100% - 2rem) / 2);
  }
}

@media (max-width: 1076px) {
  .posts-carousel {
    display: none;
  }

  .whyJoinUs {
    margin: 10% 0 10% 0;
    padding: 0;
    width: 100%;
  }

  .articleSection p.summary {
    font-size: 1.5rem;
  }

  .club-introduction-container p {
    font-size: 4vw;
    font-style: normal;
  }

  .clubTitleSection {
    height: 40vw;
  }

  .whyJoinUs p {
    font-size: 1rem;
  }
}

@media (max-width: 976px) {
  .summaryTitle {
    font-size: 2rem;
    margin: 30px;
  }

  .map-and-label {
    padding-right: 0;
  }

  .map {
    width: 70%;
  }

  .clubTitleSection {
    height: 50vw;
  }
}

@media only screen and (max-width: 768px) {
  .uw-logo {
    display: none;
  }

  .clubTitleSection {
    height: 70vw;
  }

  .goal-message-section {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    background: var(--bg);
  }

  .goal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 2.45em;
    line-height: 78px;
    padding-bottom: 40px;
    color: var(--text-title-color);
  }

  .goal-text {
    text-align: center;
    max-width: 500px;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 30px;
    color: var(--text-color);
  }

  .summaryTitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .club-introduction-container-button {
    font-size: 3vw;
  }

  .uw-logo {
    display: none;
  }

  .uw-logo {
    width: 30%;
  }

  .clubName {
    font-size: 5vw;
    color: var(--text-header-color);
  }
}
