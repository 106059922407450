body {
  background-color: var(--bg);
  font-family: "assistant", "Montserrat", "Lato", "Helvetica Neue", "Helvetica",
    sans-serif;
}

.event-page h1,
.event-page h2 {
  margin-bottom: 10px;
  font-size: 5vw;
  color: white;
  text-shadow: 1px 1px 2px var(--text-header-shadow-color);
  animation: slide-up 0.5s ease-out;
  z-index: 2;
}

.event-page h2 {
  margin-bottom: 0;
}

.event-page h3 {
  font-size: 1.5rem;
  text-align: left;
  min-width: 0;
}

.event-page p {
  color: var(--text-color);
}

.event-page h3 {
  margin: 1rem 0 0 0;
}

.event-page p {
  font-size: 1.1rem;
  margin: 0.5rem 0;
}

.event-headers {
  color: var(--text-title-color);
  text-align: center;
  font-size: 2.5rem;
  margin: 3rem 0;
}

p.event-status {
  color: var(--nav-bottom);
}

.event-page-intro {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 20vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.event-page-intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../Data/img/backgroundImg/2.jpg);
  background-size: cover;
  background-position: center;
  filter: brightness(70%);
  z-index: -1;
}

.calendar-container {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(145deg, #f3f4f6, #ffffff);
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.calendar {
  width: 70%;
  height: 700px;
  min-height: 20rem;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.calendar:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.upcoming-events-section {
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  padding: 50px 10px;
}

.upcoming-event p {
  padding: 0.5rem 0 0 0;
  font-size: 1.2rem;
}

.upcoming-event {
  display: flex;
  align-items: flex-start;
}

.upcoming-event-image {
  width: 30rem;
  border-radius: 20px;
  margin: 20px;
  margin-left: 6rem;
  height: 18rem;
  object-fit: cover;
}

.upcoming-event-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
}

.upcoming-event-info {
  max-width: 40rem;
  margin: 0 6rem 0 5rem;
  /* height: fit-content; */
}

.rsvp-btn {
  font-family: "Roboto";
  font-weight: bold;
  color: var(--text-color);
  box-sizing: border-box;
  background: var(--bg);
  padding: 4px 10px;
  border-radius: 20px;
  margin: 8px 0;
  border: 2px solid var(--bg);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}

.rsvp-btn:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.rsvp-btn:active {
  color: var(--bg);
  background-color: #7e6d90;
}

.past-events-section {
  display: flex;
  flex-direction: column;
}

.past-events {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.past-event {
  width: 25rem;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.past-event-image {
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.past-event-title {
  color: var(--text-subtitle-color);
  text-align: left;
}

@media (max-width: 1076px) {
  .event-page h1,
  .event-page h2 {
    font-size: 2rem;
    margin-top: 4rem;
  }

  .calendar {
    width: 90%;
    height: auto;
  }

  .upcoming-event {
    flex-direction: column;
    align-items: center;
  }

  .upcoming-event-image {
    /* width: 75%; */
    margin-left: 0;
  }

  .upcoming-event-info {
    margin: 0;
    width: 75%;
  }
}

@media (max-width: 976px) {
  .event-page-intro {
    height: 40vh;
  }
}

@media (max-width: 572px) {
  .upcoming-event-image {
    width: 80%;
    margin: 0 16rem;
    margin-top: 15px;
    height: 15rem;
    /* object-fit: cover; */
  }

  .upcoming-event-info {
    width: 75%;
  }

  .rsvp-btn {
    margin-bottom: 20px;
  }

  .event-page h1 {
    margin: 26px 0;
  }

  .event-page h2 {
    margin-bottom: 0;
  }
}

@media (max-width: 470px) {
  .upcoming-event-image {
    width: 85%;
    margin: 0 5%;
    margin-top: 15px;
    height: 100%;
  }

  .event-page h1,
  .event-page h2 {
    font-size: 4rem;
  }
}
