:root {
  --none: #c9c9c9;
  --active: #c6a3ff;
  --completed: #957dff;
  --height: 50px;
}

.content-flex-small {
  display: none;
}

.content-flex-small p {
  color: var(--text-subtitle-color);
}

.css-1hv8oq8-MuiStepLabel-label,
.back-btn {
  color: var(--text-subtitle-color) !important;
}

.css-1di5t78-MuiPaper-root {
  background-color: var(--bg-accent) !important;
}

.background-image {
  background: linear-gradient(
      0deg,
      rgba(190, 184, 235, 0.8),
      rgba(190, 184, 235, 0.8)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    linear-gradient(0deg, rgba(190, 184, 235, 0.1), rgba(190, 184, 235, 0.1)),
    url(../Data/img/man-on-computer.jpg);
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
}

.js-white-box {
  display: flex;
  flex-direction: column;
  margin: 15%;
  border: 1.5px solid #3d3061;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  width: 400%;
  height: 200%;
  background-color: var(--bg-accent);
}

td {
  width: 500px;
}

.content-flex {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-images {
  display: flex;
  justify-content: center;
}

.completion-bar {
  display: flex;
  width: 400px;
}

.link-boxes {
  border: 2px solid #3d306180;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 20px;
  text-decoration: none;
  border-radius: 20px;
  background-color: #e5e2ed;
  color: var(--text-color);
  animation: slide-up 0.5s ease;
  transition: all 0.2s ease;
}

.link-boxes:hover {
  text-decoration: none;
  color: #e5e2ed;
  background-color: #3d3061;
  transition: all 0.2s ease;
}

.separate-insta-linkedin {
  margin: 7.5px;
}

div.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px 40px;
  width: 90%;
  height: 20px;
}

button.click-button.next-button {
  margin-left: auto;
}

.click-button {
  display: flex;
  border-radius: 15px;
  border-color: #9c7ef3;
  background-color: #9c7ef3;
  padding: 10px 20px;
  margin: 5px 15px;
  color: #e5e2ed;
  border: 0;
  animation: slide-up 0.5s ease;
  transition: all 0.2s ease;
}

.click-button:hover {
  cursor: pointer;
  background-color: var(--text-header-color);
  transition: all 0.2s ease;
}

a.link-boxes {
  color: var(--text-header-color);
}

.join-message {
  color: var(--text-subtitle-color);
  font-size: 150%;
  margin: 10px;
}

.action-item {
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.thanks-box {
  justify-self: center;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 120%;
  background-color: #beb8eb80;
  border: 1.5px solid var(--border);
  text-align: center;
  color: var(--text-text-color);
  max-width: 30rem;
}

div.info-title {
  color: var(--text-title-color);
}

.thanks-message {
  padding: 5px 25px;
  margin: 10px;
  color: var(--text-subtitle-color);
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: separate;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-title {
  font-style: normal;
  font-size: 200%;
  color: #3d3061;
  text-align: center;
}

.info-text {
  margin: 10px auto;
  font-size: 20px;
  color: var(--text-color);
  text-align: center;
  width: 60%;
}

.community-benefits {
  margin: 3rem;
}

.example-images {
  display: flex;
  align-self: center;
  height: 10rem;
  width: 10rem;
}

.entire {
  display: flex;
  flex-direction: column;
}

.stepper-wrapper {
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  width: 200px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 4px solid #ccc;
  width: 100%;
  top: calc(var(--height) / 2);
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: calc(var(--height) / 2);
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--height);
  height: var(--height);
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item .step-counter {
  background-color: var(--none);
}

.stepper-item.active .step-counter {
  background-color: var(--active);
}

.stepper-item.completed .step-counter {
  background-color: var(--completed);
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #957dff;
  width: 100%;
  top: calc(var(--height) / 2);
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.step-name {
  color: var(--text-subtitle-color);
}

.stepper-item.completed .step-counter {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .about-title,
  .team-members-title {
    font-size: 2rem;
    line-height: 50px;
  }
  .about-text,
  .team-members-subtitle {
    font-size: 1.5rem;
    margin: 1rem 1.5rem;
    line-height: 30px;
  }

  .about-message-section {
    height: 250px;
  }

  .content-flex-small {
    display: block;
    padding: 3rem;
    width: 80%;
  }

  .js-white-box {
    margin: 5rem;
    width: 70%;
    height: 70%;
  }

  .js-white-box .content-flex,
  .js-white-box .button-container {
    display: none;
  }

  /* testing */
  .join-section table {
    width: 100%;
  }

  table {
    align-items: center;
  }

  .join-section td {
    display: block;
    padding: 20px 0;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .action-item a {
    font-size: 1rem;
  }

  p.thanks-message {
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 524px) {
  .action-item a {
    display: flex;
  }

  td {
    width: 300px;
  }
}
