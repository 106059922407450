.card-container {
  padding: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

.card {
  box-sizing: border-box;
  width: 15em;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-image-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.card-image-container::before {
  padding: 70%;
  height: auto;
  content: "";
  position: relative;
  background-image: url("../Data/img/frameProfileCard.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: drop-shadow(2px 5px rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.founder-card::before {
  filter: invert(1) drop-shadow(2px 5px rgba(0, 0, 0, 0.3));
}

.card-image-container img {
  width: 58%;
  height: 58%;
  object-fit: cover;
  position: absolute;
  margin: 10%;
  border-radius: 8px;
}

.card p {
  color: var(--card-text);
  letter-spacing: 0.5px;
  text-align: center;
}

.name {
  font-size: 1rem;
  color: var(--card-text);
  font-weight: bold;
  letter-spacing: 0.8px;
  margin: 0;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container button {
  margin: 12px 8px;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.4s;
}

.card-container button:hover {
  opacity: 0.8;
  transition: 200ms;
}

.card-container button a {
  color: #fff;
  font-size: 1.4em;
}

.card-container button svg {
  padding-top: 4px;
  height: 20px;
}

.card-container button.portfolio {
  background-color: #3c8c2d;
}

.card-container button.github {
  background-color: #24292e;
}

.card-container button.linkedin {
  background-color: #2d64bc;
}

.card-container button.email {
  background-color: #aa5738;
}

@media only screen and (max-width: 768px) {
  .card-container {
    padding: 0;
    gap: 15vw;
  }
  .card {
    width: 8rem;
  }

  .card-container button {
    width: 30px;
    height: 30px;
  }
}
