.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin: auto 0;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #b6b6b6;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #333;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #333;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #9176f1;
}

.toggle-switch-stop-img-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.toggle-switch-stop-img {
  max-height: 40vw;
  width: auto;
}

.toggle-switch-stop-img.hide {
  animation: popIn 0.3s ease-out backwards;
}

.toggle-switch-stop-img.show {
  animation: popOut 0.5s ease-in forwards;
}

@keyframes popOut {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}
